<template>
  <Layout>
    <div v-if="isBusy" style="text-align: center">
      <strong>Loading...</strong>
    </div>
    <div v-else>
      <div class="row" v-if="display_device">
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-12">
                  <strong>Display Device Info</strong>
                  <!-- <b-button
                    variant="primary"
                    size="sm"
                    class="float-right"
                    @click="$bvModal.show(`update-advertiser-modal`)"
                  >
                    Edit
                  </b-button> -->
                </div>
              </div>

              <div class="row">
                <div class="col-4">Serial number</div>
                <div class="col-8">
                  {{ display_device.serial_number }}
                </div>
              </div>
              <div class="row">
                <div class="col-4">Created At</div>
                <div class="col-8">
                  {{ moment(display_device.createdAt).format("LLL") }}
                </div>
              </div>
              <div class="row">
                <div class="col-4">Updated At</div>
                <div class="col-8">
                  {{ moment(display_device.updatedAt).format("LLL") }}
                </div>
              </div>

              <div class="row">
                <div class="col-4">Last Online</div>
                <div class="col-8">
                  {{ moment(display_device.last_online).format("LLL") }}
                </div>
              </div>

              <div class="row">
                <div class="col-4">Status</div>
                <div class="col-8">
                  <b-badge v-if="display_device.status === 1">Active</b-badge>
                  <b-badge v-else>InActive</b-badge>
                </div>
              </div>
            </div>
          </div>
          <div class="mt-1">
            <b-tabs content-class="mt-3">
              <b-tab title="Packages" active>
                <div class="d-flex justify-content-end my-2">
                  <!-- <add-company-modal
                    v-on:add="companyAdded"
                    :id="advertiser._id"
                    :data="advertiser._id"
                  /> -->
                </div>

                <div class="d-flex justify-content-end my-2"></div>

                <div v-if="packages.length === 0" class="text-center">
                  No Package
                </div>
                <div class="row" v-else>
                  <div class="col-md-6" v-for="item in packages" :key="item.id">
                    <div class="card p-2">
                      <!-- <edit-company-modal
                        v-on:edit="companyEdited"
                        :data="company"
                      /> -->
                      <div>
                        <strong>{{ item.name }}</strong>
                      </div>
                      <div>Device Id: {{ item.device_id }}</div>
                      <div>
                        Created At : {{ moment(item.createdAt).format("LLL") }}
                      </div>
                      <div>
                        Updated At : {{ moment(item.updatedAt).format("LLL") }}
                      </div>
                      <div>
                        Version : {{ moment(item.version).format("LLL") }}
                      </div>

                      <br />
                    </div>
                  </div>
                </div>
              </b-tab>
              <b-tab title="Actions">
                <div class="d-flex justify-content-end my-2">
                  <add-action-modal
                    v-on:add="companyAdded"
                    :id="display_device._id"
                    :data="display_device._id"
                    @formSubmitted="handleFormSubmitted"
                  />
                </div>

                <div class="d-flex justify-content-end my-2"></div>

                <div v-if="actions.length === 0" class="text-center">
                  No Actions
                </div>
                <div class="row" v-else>
                  <div class="col-md-6" v-for="item in actions" :key="item.id">
                    <div class="card p-2">
                      <!-- <edit-company-modal
                        v-on:edit="companyEdited"
                        :data="company"
                      /> -->
                      <div>
                        <strong>{{ item.name }}</strong>
                      </div>
                      <div>Device Id: {{ item.device_id }}</div>
                      <div>Action: {{ item.action }}</div>
                      <div>Details: {{ item.details }}</div>
                      <div>Status: {{ item.status===1?'Done':'New' }}</div>

                      <div>
                        Created At : {{ moment(item.createdAt).format("LLL") }}
                      </div>
                      <div>
                        Updated At : {{ moment(item.updatedAt).format("LLL") }}
                      </div>
                      <div>
                        Version : {{ moment(item.version).format("LLL") }}
                      </div>

                      <br />
                    </div>
                  </div>
                </div>
              </b-tab>
            </b-tabs>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
<style>
.vehicleinfo {
  cursor: pointer;
}
</style>
<script>
import Layout from "../../layouts/main";

import { display_device } from "@/config/api/display_device";
import AddActionModal from "./add-action-modal.vue";
export default {
  props: ["id"],
  components: {
    Layout,
    AddActionModal,
  },
  data() {
    return {
      isBusy: false,
      loadingAds: false,
      display_device: undefined,
      packages: undefined,
      actions: undefined,

      currentPage: 1,
      ads: [],
      rows: 0,
      perPage: 12,
      filterData: [],
      submitting: false,
      submit: false,
    };
  },
  mounted() {
    // this.loadAdvertiserProfile();
    this.loadDevices();
  },
  methods: {
    handleFormSubmitted() {
      this.loadDevices();
    },
    loadDevices() {
      this.isBusy = true;
      const api = display_device.getById;
      api.id = this.id;
      this.loadingAds = true;
      this.generateAPI(api)
        .then((res) => {
          this.display_device = res.data.device;
          this.packages = res.data.packages;
          this.actions = res.data.actions;
          this.isBusy = false;
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          this.loadingAds = false;
        });
    },
  },
};
</script>

<style>
.spaceDetails {
  justify-content: space-between;
}

.trade-licence-download {
  background-color: black;
  color: white;
  padding: 2px 6px;
  margin: 8px;
  border-radius: 4px;
  font-size: 12px;
  max-width: 88px;
}

.trade-licence-download:hover {
  color: gray;
}

#profile-ads .card {
  box-shadow: 0px 0px 5px 0px rgb(118 118 118 / 75%);
  -webkit-box-shadow: 0px 0px 5px 0px rgb(118 118 118 / 75%);
  -moz-box-shadow: 0px 0px 5px 0px rgb(118 118 118 / 75%);
  cursor: pointer;
}

.payment-due {
  background-color: #9d0000;
  color: white;
}

.payment-success {
  background-color: #143e14;
  color: white;
}
</style>

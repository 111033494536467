<template>
  <div class="update-company-modal">
    <b-button
      variant="primary"
      size="sm"
      class="float-right"
      @click="$bvModal.show(`add-company-modal-${data}`)"
    >
      <i class="ri-add-box-line"></i>
      Create Action
    </b-button>

    <b-modal
      ref="updateCompanyModal"
      :id="`add-company-modal-${data}`"
      title="Add Company"
      hide-footer
    >
      <form @submit.prevent="addComapny">
        <div class="form-group">
          <label for="">Action</label>
          <input
            v-model="form.action"
            type="text"
            class="form-control"
            name="action"
            :class="{ 'is-invalid': submit && $v.form.action.$error }"
            placeholder="Action here"
          />
          <div v-if="submit && $v.form.action.$error" class="invalid-feedback">
            <span v-if="!$v.form.action.required">Action is required.</span>
          </div>
        </div>

        <div class="form-group">
          <label for="">Details</label>
          <input
            v-model="form.details"
            type="text"
            class="form-control"
            name="details"
            :class="{
              'is-invalid': submit && $v.form.details.$error,
            }"
            placeholder="Details here"
          />
          <div v-if="submit && $v.form.details.$error" class="invalid-feedback">
            <span v-if="!$v.form.details.required"
              >Details number is required.</span
            >
          </div>
        </div>

        <div class="form-group mb-0 mt-2">
          <div class="d-flex justify-content-end">
            <b-button type="submit" variant="primary" size="sm">
              <span v-if="submitting">Submitting...</span>
              <span v-else> Submit </span>
            </b-button>
          </div>
        </div>
      </form>
    </b-modal>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import { display_device } from "@/config/api/display_device";

export default {
  props: ["data"],
  data() {
    return {
      form: {
        action: "",
        details: "",
      },
      submitting: false,
      submit: false,
    };
  },
  validations: {
    form: {
      action: { required },
      details: {},
    },
  },
  mounted() {},
  methods: {
    addComapny() {
      this.submit = true;
      this.$v.$touch();
      if (!this.$v.$invalid) {
        const api = display_device.createDisplayDevice;
        this.form.device_id = this.data;
        api.data = this.form;
        this.submitting = true;
        this.generateAPI(api)
          .then(() => {
            this.$bvToast.toast("Action added successfully!", {
              title: "Create",
              variant: "success",
              solid: true,
            });
            this.$v.$reset();
            this.form = {
              details: "",
              action: "",
            };
            this.$emit("formSubmitted");
            this.$bvModal.hide("add-company-modal");
          })
          .catch((err) => {
            this.$bvToast.toast(
              `Action is not added, ${err.response.data.error.details}!`,
              {
                title: "Create",
                variant: "danger",
                solid: true,
              }
            );
          })
          .finally(() => {
            this.submitting = false;
          });
      }
    },
  },
};
</script>

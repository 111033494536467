export const display_device = {
  list: {
    url: "/display_ad/get_devices",
    method: "GET",
    params: null,
  },
  getById:{
    url: `/display_ad/get_device_detail_by_id/`,
    method: "GET",
    params: null,
    id: null,
  },
  createDisplayDevice: {
    url: "/display_ad/action/create",
    method: "POST",
    id: null,
    data: null,
  },

};
